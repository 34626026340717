var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "root" } }, [
    _c("div", { staticClass: "fixed-background" }, [
      _c("main", [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "b-row",
              { staticClass: "h-100" },
              [
                _c(
                  "b-colxx",
                  {
                    staticClass: "mx-auto my-auto",
                    attrs: { xxs: "12", md: "10" },
                  },
                  [
                    _c(
                      "b-card",
                      { staticClass: "auth-card", attrs: { "no-body": "" } },
                      [
                        _c("div", {
                          staticClass: "position-relative image-side",
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-side" },
                          [
                            _c("h6", { staticClass: "mb-4" }, [
                              _vm._v("Ooops... looks like an error occurred!"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "mb-0 text-muted text-small mb-0",
                              },
                              [
                                _vm._v(
                                  "\n                  Error code\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "display-1 font-weight-bold mb-5",
                              },
                              [_vm._v("404")]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-shadow",
                                attrs: {
                                  type: "submit",
                                  variant: "primary",
                                  size: "lg",
                                },
                                on: { click: _vm.goBack },
                              },
                              [
                                _vm._v(
                                  "\n                  GO BACK HOME\n                "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }